.content-wrapper {
  .card-wrapper {
    &.card-table-ui {
      padding-left: 0;
      padding-right: 0;
      .card-title {
        padding-left: 24px;
        padding-right: 24px;
      }
    }

    .field-icon {
      position: relative;
      .rupee-symbol {
        position: absolute;
        width: 39.5px;
        height: 39.5px;
        border-radius: 8px 0px 0px 8px;
        background-color: var(--primary-text-color);
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        bottom: 0;
        color: white !important;
        top: 0;
      }
      .icon {
        position: absolute;
        width: 39.5px;
        height: 39.5px;
        border-radius: 0px 8px 8px 0px;
        background-color: var(--primary-text-color);
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0;
        bottom: 0;
        top: 0;
        svg {
          font-size: 20px;
          path {
            color: var(--white);
          }
        }
      }
    }
  }
}

.side-drawer {
  .MuiDrawer-paper {
    .sidebar-tab-wrapper {
      &.activity-log-wrapper {
        .sidebar-tab {
          .tab-label-btn {
            padding-left: 0;
            padding-right: 0;
          }

          .MuiTabs-indicator {
            display: none;
          }
        }
        .add-activity-log-btn {
          justify-content: flex-end;
          display: flex;
          width: 100%;
          button {
            margin-right: 10px;
          }
        }
        .filter-content-wrapper {
          max-width: 1000px;
          width: 100%;

          @media (max-width: 991.98px) {
            max-width: 950px;
            width: 100%;
          }
          @media (max-width: 768.98px) {
            max-width: 750px;
            width: 100%;
          }
        }
        .contact-label {
          color: var(--primary-text-color);
          font-weight: 700;
          cursor: default;
        }
        .activity-log-list-wrapper {
          display: flex;
          flex-direction: column;
          max-height: calc(100vh - 160px);
          padding-right: 10px !important;
          overflow: auto;
          .activity-card-wrapper {
            display: flex;
            align-items: flex-start;
            gap: 24px;
            position: relative;
            padding-bottom: 36px !important;
            &:not(:last-child) {
              &:before {
                content: "";
                width: 2px;
                height: 100%;
                position: absolute;
                left: 25px;
                background: rgba(217, 217, 217, 0.5);
                z-index: -1;
              }
            }

            .activity-log-icon {
              min-width: 50px;
              height: 50px;
              border-radius: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              svg {
                width: 24px;
                height: 24px;
              }
              &.activity-create {
                background-color: #ebf1fb;
                svg {
                  path {
                    color: #2e77f2;
                  }
                }
              }
              &.activity-status {
                background-color: #f8e3e3;
                svg {
                  path {
                    color: #fa5757;
                  }
                }
              }
              &.activity-assign {
                background-color: #f3e4fc;
                svg {
                  path {
                    color: #62009d;
                  }
                }
              }
              &.activity-estimation {
                background-color: #ffecda;
                svg {
                  path {
                    color: #f0841e;
                  }
                }
              }
              &.activity-edit {
                background-color: #f1feff;
                svg {
                  path {
                    color: #41dbe7;
                  }
                }
              }
              &.activity-call {
                background-color: #e9ffbb;
                svg {
                  path {
                    color: #7cb900;
                  }
                }
              }
            }
            .activity-content-wrapper {
              display: flex;
              align-items: flex-start;
              gap: 24px;
              padding-top: 16px !important;
              width: 100%;
              span {
                color: var(--textgrey);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                white-space: nowrap;
                line-height: normal;
              }
              .activity-content {
                max-width: calc(100% - 100px);
                width: 100%;
                .activity-content-header {
                  display: flex;
                  // align-items: center;
                  gap: 10px;
                  justify-content: space-between;
                  h6 {
                    color: var(--primary-text-color);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    position: relative;
                    top: -3px;
                  }
                  span {
                    color: var(--textgrey);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    white-space: nowrap;
                    position: relative;
                    top: -3px;
                  }
                }
                .activity-content-para {
                  border-radius: 10px;
                  background: rgba(124, 185, 0, 0.05);
                  padding: 16px !important;
                  margin-top: 18px;
                  p {
                    color: var(--primary-text-color) !important;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.side-drawer
  .MuiDrawer-paper
  .sidebar-tab-wrapper
  .filter-content-wrapper
  .MuiBox-root {
  padding: 0 !important;
}
.filer-accordion-wrapper {
  overflow: auto;
  max-height: calc(100vh - 65px);
  .MuiAccordion-root {
    // border: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--border) !important;
    &.Mui-expanded {
      margin: 0;
    }
    &:before {
      display: none !important;
    }
    box-shadow: unset;
    border-radius: 0;
    .MuiAccordionSummary-expandIconWrapper {
      background-color: var(--edit-btn);
      width: 30px;
      height: 30px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: var(--edit-btn-hover);
      }
      svg {
        width: 20px;
        height: 20px;
        path {
          color: var(--primary-text-color);
        }
      }
    }
    .accordion-title {
      padding: 12px 24px;
      min-height: auto !important;
      .action-btn {
        min-width: 30px !important;
        max-width: 30px;
        height: 30px;
        padding: 0 !important;
        svg {
          width: 20px;
          height: 20px;
        }
      }
      .MuiAccordionSummary-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 16px;
        margin: 0;
        .title-content {
          color: var(--primary-text-color) !important;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
      }
    }
    .filter-accordion-details {
      padding: 12px 24px 0 24px;
      .saved-filter-wrapper {
        margin-bottom: 12px;
        .saved-filter-content {
          display: flex;
          align-items: flex-start;
          span {
            font-feature-settings: "clig" off, "liga" off;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.4px;
            &.title {
              color: var(--textgrey);
              width: 150px;
              white-space: nowrap;
            }
            &.desc {
              color: var(--primary-text-color);
            }
          }
        }
      }
    }
  }
}
.rs-picker-date-menu {
  z-index: 9999 !important;
  .rs-btn.rs-btn-primary {
    background-color: var(--primary-text-color);
    color: #fff;
  }
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
    background-color: var(--green);
    color: #fff !important;
  }
  .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
    background-color: var(--primary-text-color);
  }
  .rs-calendar-time-view .rs-calendar-header-title-time {
    color: var(--primary-text-color);
  }
  .rs-calendar-time-dropdown-cell:hover,
  .rs-calendar-table-cell-content:hover {
    background-color: var(--secondary-text-color);
    color: var(--priamry-text-color);
  }
}
.modal-date-picker {
  width: 100%;
  border-color: var(--border);
  border-radius: 10px;
  .rs-picker-toggle.rs-btn {
    border-radius: 10px;
    padding-top: 9.2px !important;
    padding-bottom: 9.2px !important;
    .rs-picker-toggle-placeholder,
    .rs-picker-toggle-textbox {
      color: rgba(35, 35, 35, 0.3);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .rs-picker-toggle-value {
      color: var(--primary-text-color);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &:not(.rs-picker-disabled) .rs-picker-toggle-active,
  &:hover .rs-picker-toggle {
    border-color: var(--primary-text-color) !important;
    box-shadow: unset !important;
  }
}

.side-drawer {
  .MuiDrawer-paper {
    .sidebar-tab-wrapper {
      .tab-label-btn-1 {
        color: var(--primary-text-color);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        opacity: 1 !important;
        &.Mui-selected {
          color: var(--primary-text-color) !important;
        }
      }
    }
  }
}

.filter-button-wrapper-1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
  .btn {
    width: 120px;
  }
}

.filter-content-wrapper .MuiBox-root {
  padding: 0px !important;
}

.error {
  color: var(--red) !important;
  font-size: 14px !important;
}

.close-btn span svg {
  fill: black !important;
}

.no-msg {
  font-size: 16px;
  font-weight: 500;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
}

.pagination-wrapper-1 {
  text-align: center;
  margin: 24px;
  nav {
    ul {
      justify-content: center;
      li {
        button {
          font-size: 15px;
        }
        button.Mui-selected {
          background-color: var(--edit-btn);
        }
      }
    }
  }
}

.asset-module-wrapper {
  flex-wrap: wrap-reverse;
  gap: 24px;
}
.warranty-wrapper {
  gap: 24px;
  display: flex;
}

.view-activity-log {
  .date-time-drawer {
    display: flex;
    .mobile-time-drawer {
      display: none;
      @media (max-width: 768.98px) {
        display: flex;
        margin-left: 4px;
      }
    }
  }
}
.side-drawer {
  .MuiDrawer-paper {
    .sidebar-tab-wrapper {
      &.activity-log-wrapper {
        .inner-tab-wrapper {
          .sidebar-tab {
            .MuiTabs-indicator {
              display: block;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768.98px) {
  .asset-module-wrapper {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    .filter-apply-wrapper {
      width: 100%;
      justify-content: flex-start;
    }
    .content-header-right {
      width: 100%;
      justify-content: flex-start;
      .search-bar {
        width: 100%;
        flex: 1;
        input {
          width: 100%;
        }
      }
      .owner-select-box {
        width: 100%;
        .select-owner {
          width: 100%;
        }
      }
    }
  }
  .asset-view-module-wrapper {
    flex-wrap: wrap-reverse;
    gap: 24px;
  }
  .side-drawer {
    &.view-activity-log {
      .sidebar-tab-wrapper {
        &.activity-log-wrapper {
          .activity-log-list-wrapper {
            .activity-card-wrapper {
              .activity-content-wrapper {
                padding-top: 8px !important;
              }
            }
          }
        }
      }
    }
    .activity-content-wrapper {
      flex-direction: column;
      gap: 8px !important;
      .activity-content {
        max-width: 100% !important;
      }
    }
    .activity-content-header {
      span {
        display: none;
      }
    }
  }
}
@media (max-width: 700.98px) {
  .asset-view-module-wrapper {
    .content-header-right {
      width: 100%;
      .primary-btn {
        flex: 1;
      }
    }
  }
}
@media (max-width: 576.98px) {
  .asset-module-wrapper {
    .content-header-right {
      .search-bar {
        flex: 1 auto;
      }
      .primary-btn {
        flex: 1;
      }
    }
  }
}
@media (max-width: 480.98px) {
  .asset-view-module-wrapper {
    .content-header-right {
      width: 100%;
      .primary-btn {
        flex: 1 auto;
        width: 100%;
      }
    }
  }
}
@media (max-width: 430.98px) {
  .filter-content-wrapper {
    .filter-button-wrapper-1 {
      flex-wrap: wrap;
      .primary-btn {
        flex: 1;
      }
      .primary-btn,
      .secondary-btn {
        flex: 1;
      }
      .green-btn {
        width: 100% !important;
        flex: 1 auto;
      }
    }
  }
  .warranty-wrapper {
    width: 100%;
    flex-direction: column;
  }
}
@media (max-width: 360.98px) {
  .asset-module-wrapper {
    .content-header-right {
      .primary-btn {
        width: 100%;
        flex: 1 auto;
      }
    }
  }
}
