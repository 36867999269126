.content-wrapper{
  margin: 24px 32px 32px 32px;
  .p-24{
    padding: 24px;
    &> .MuiBox-root{
      padding: 0 !important;
    }
  }
  .mb-24{
    margin-bottom: 24px !important;
  }
  .divider{
    border-color: var(--body-bg);
  }
  .card-wrapper{
    background-color: var(--white);
    border-radius: 12px;
    padding: 24px;
  }
  .view-info{
    .label{
      font-size: 14px;
    }
    span{
      font-size: 15px;
      padding: 0;
    }
    .tag-wrap{
      flex-wrap: wrap;
      .MuiChip-root{
        border-radius: 6px;
        background: rgba(217, 217, 217, 0.50);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.4px;
        padding: 8px 16px;
      }
    }
  }
  .drop-zone {
    .drop-zone-inner {
      background-color: var(--secondary-text-color);
      box-shadow: none;
      border-radius: 5px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 24px;
      .msg-wrap {
        border: 2px dotted var(--textgrey);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: var(--white);
        width: 280px;
        height: 280px;
        border-radius: 50%;
        cursor: pointer;
        h6 {
          color: var(--primary-text-color);
          font-size: 15px;
          margin: 16px 0 8px 0;
          font-weight: 600;
        }
        .sub-msg {
          font-size: 13px;
          margin: 0 0;
          color: var(--primary-text-color) !important;
  
          span {
            font-weight: 500;
          }
        }
        svg {
          font-size: 28px;
        }
      }
    }
    &.video-cast-dropzone{
      .drop-zone-inner{
        .msg-wrap{
          height: 250px;
          width: 250px;
        }
      }
    }
    &.screenshot-cast-dropzone{
      .drop-zone-inner{
        .msg-wrap{
          height: 250px;
          width: 250px;
          margin-top: 43.5px;
          margin-bottom: 44px;
          @media(max-width:768.98px){
            margin: 0;
          }
        }
      }
    }
  }
  .dashboard-count-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    span{
      color: var(--primary-text-color);
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      white-space: nowrap;
    }
    h2{
      color: var(--primary-text-color);
      font-size: 36px !important;
      font-style: normal;
      line-height: normal;
    }
    .dashboard-btn{
      background-color: transparent;
      color: var(--textgrey);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      text-transform: capitalize;
      box-shadow: none !important;
      padding: 0 !important;
      width: fit-content !important;
      min-width: auto !important;
      svg{
        path{
          color: var(--textgrey);
        }
      }
      &:hover{
        background-color: transparent;
      }
    }
    .dashboard-count-icon{
      width: 80px;
      height: 80px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg{
        width: 50px;
        height: 50px;
      }
      &.in-service{
        background-color: var(--export-btn);
        svg{
          path{
            color: var(--export-btn-icon);
          }
        }
      }
      &.out-of-service{
        background-color: var(--view-btn);
        svg{
          path{
            color: var(--view-btn-icon);
          }
        }
      }
      &.open-ticket{
        background-color: var(--convert-btn);
        svg{
          path{
            color: var(--convert-btn-icon);
          }
        }
      }
      &.close-ticket{
        background-color: var(--delete-btn);
        svg{
          path{
            color: var(--delete-btn-icon);
          }
        }
      }
    }
  }
  .chart-list-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .chart-more-btn{
      width: 30px;
      height: 30px;
      background-color: var(--textgrey);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      cursor: pointer;
      svg{
        width: 24px;
        height: 24px;
        path{
          color: var(--white);
        }
      }
     
    }
  }

}
.more-menu{
  ul{
    padding: 0;
    li{
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      color: var(--textgrey);
      padding: 16px;
    }
  }
  .divider-space{
    margin: 0 !important;
  }
}
.muilt-select-field{
  .select__control{
    border-radius: 10px;
    min-height: 40px;
    &:hover, &:focus, &.select__control--is-focused{
      border-color: var(--primary-text-color) !important;
      box-shadow: none;
    }
    .select__multi-value{
      border-radius: 6px;
      // height: 24px;
      padding: 6px;
      color: var(--textgrey);
      font-size: 14px !important;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      background-color: rgba(217, 217, 217, 0.50);
    }
  }
  &.has-error {
    .select__control {
      border-color: var(--red); 
    }
  }
}
.side-drawer{
  .MuiDrawer-paper{
    max-width: 450px;
    width: 100%;
    .sidebar-tab-wrapper{
      .close-btn{
        width: 20px;
        height: 20px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        right: 24px;
        top: 12px;
        z-index: 99;
        svg{
          font-size: 20px;
          path{
            color: var(--primary-text-color);
          }
        }
      }
      .sidebar-tab{
        padding: 0 24px 0 24px;
        border-bottom: 1px solid var(--border);
        .tab-label-btn{
          color: var(--textgrey);
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
          &.Mui-selected{
            color: var(--primary-text-color);
          }

        }
        .MuiTabs-indicator{
          height: 3px;
          background-color: var(--primary-text-color);
          border-radius: 5px;
        }
      }
      .filter-button-wrapper{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
        margin-top: 24px;
        .btn{
          width: 120px;
        }
      }
      .filter-content-wrapper{
        .MuiBox-root{
          padding: 0 !important;
          .filer-accordion-wrapper{
            overflow: auto;
            max-height: calc(100vh - 65px);
            .MuiAccordion-root{
              padding-top: 12px;
              padding-bottom: 12px;
              border-bottom: 1px solid var(--border) !important;
              &.Mui-expanded{
                margin: 0;
              }
              &:before{
               display: none !important;
              }
              box-shadow: unset;
              border-radius: 0;
              .MuiAccordionSummary-expandIconWrapper{
                background-color: var(--edit-btn);
                width: 30px;
                height: 30px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover{
                  background-color: var(--edit-btn-hover);
                }
                svg{
                  width: 20px;
                  height: 20px;
                  path{
                    color:var(--primary-text-color)
                  }
                }
              }
              .accordion-title{
                padding:12px 24px;
                min-height: auto !important;
                .action-btn{
                    min-width: 30px !important;
                    max-width: 30px;
                    height: 30px;
                    padding: 0 !important;
                    svg{
                      width: 20px;
                      height: 20px;
                    }
                }
                .MuiAccordionSummary-content{
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding-right: 16px;
                  margin: 0;
                  .title-content{
                    color: var(--primary-text-color) !important;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                  }
                }
              }
              .filter-accordion-details{
                padding: 12px 24px 0 24px; 
                .saved-filter-wrapper{
                  margin-bottom: 12px;
                  .saved-filter-content{
                    display: flex;
                    align-items: flex-start;
                    span{                      
                      font-feature-settings: 'clig' off, 'liga' off;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      letter-spacing: 0.4px;
                      &.title{
                       color: var(--textgrey);
                       width: 150px;
                       white-space: nowrap;
                      }
                      &.desc{
                       color: var(--primary-text-color);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }     
    }
  }
}

.content-header {
  &.project-list-header{
    @media(max-width:1024.98px){
      gap: 16px;
    }
    .content-header-right{
      flex-wrap: wrap;
      @media(max-width:1024.98px){
        // justify-content: flex-end;
      } 
    }
  }
}

.report-side-drawer{
  .MuiDrawer-paper{
    max-width: 450px;
    width: 100%;
  }
}

@media(max-width:889.98px){
  .open-ticket-table-wrapper{
    height: auto !important;
    .tickets-listing{
      min-height: auto !important;
    }
  }
}
