.side-drawer {
  &.view-activity-log {
    .MuiDrawer-paper {
      max-width: 840px;
    }
  }
}
.tickets-listing {
  overflow: auto;
  max-height: calc(100vh - 250px);
  .table-header {
    position: sticky;
    top: 0;
    // z-index: 9;
    background-color: white;
  }
}

.input-field-123 {
  .MuiOutlinedInput-root {
    border-radius: 8px;
    border-color: var(--border) !important;
    &:focus-visible {
      outline: none !important;
    }
    &.Mui-focused {
      fieldset {
        border-width: 1px !important;
        border-color: var(--primary-btn-hover) !important;
      }
    }
    &.Mui-disabled {
      color: var(--primary-text-color);
      background-color: var(--secondary-text-color);
      input {
        -webkit-text-fill-color: var(--primary-text-color);
      }
    }
  }
}

.input-field-123 fieldset {
  border-color: hsl(0, 0%, 80%) !important;
}

.file-delete {
  display: flex;
  align-items: center;
  padding: 6px;

  .file-name-delete {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
  }

  .file-action-btn-delete {
      height: 28px !important;
      min-width: 28px !important;
      border-radius: 6px !important;
      font-size: 16px !important;
  }
}

.asset-ticket-module-wrapper{
  flex-wrap: wrap-reverse;
  gap: 24px;
}
.asset-ticket-view-wrapper{
  flex-wrap: wrap-reverse;
  gap: 24px;
}
@media(max-width:700px){
  .asset-ticket-module-wrapper{
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    h2{
      width: 100%;
    }
    .content-header-right{
      width: 100%;
      .search-bar{
        width: 100%;
        flex: 1;
        input{
          width: 100%;
        }
      }
    }
  }
}
@media(max-width:530px){
  .asset-ticket-view-wrapper{
    .content-header-right{
      width: 100%;
      .add-btn{
        flex: 1;
      }
    }
  }
}
@media(max-width:500px){
  .asset-ticket-module-wrapper{
    .content-header-right{
      .search-bar{
        width: 100%;
        flex: 1 auto;
      }
      .primary-btn{
        flex: 1;
      }
    }
  }
}