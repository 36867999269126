@import "rsuite/dist/rsuite.css";

// Color Variables //
:root {
  --primary-text-color: #232323;
  --white: #ffffff;
  --secondary-text-color: #f0f0f0;
  --textgrey: #a5a5a5;
  --green: #7cb900;
  --red: #ee5e5e;
  --light-green: #ebf5d9;
  --body-bg: #f4f4f4;
  --color-black: #323232;
  --color-green: #8cc714;
  --black: #323232;
  --grey: #777777;
  --primary-btn-hover: #474747;
  --secondary-btn-hover: #e0e0e0;
  --green-btn-hover: #86cd05;
  --view-btn: #edf8f5;
  --view-btn-icon: #4cbc9a;
  --view-btn-hover: #def5ef;

  --edit-btn: #e9e9e9;
  --edit-btn-hover: #e2dede;

  --delete-btn: #ffeff0;
  --delete-btn-icon: #ff606b;
  --delete-btn-hover: #fbdfe1;

  --export-btn: #ebf4fa;
  --export-btn-icon: #3eabcd;
  --export-btn-hover: #d1e7f7;

  --convert-btn: rgba(85, 76, 188, 0.1);
  --convert-btn-icon: #574cd5;
  --convert-btn-hover: rgba(85, 76, 188, 0.2);

  --scrollbar-thumb: #d9d9d9;
  --border: #dddddd;
}
.text-green {
  color: var(--green) !important;
}

.text-black {
  color: var(--black) !important;
}

.text-grey {
  color: var(--grey) !important;
}
// Font Faces //
@font-face {
  font-family: "Mona-Sans";
  src: url("../assets/fonts/Mona-Sans-SemiBold.eot");
  src: url("../assets/fonts/Mona-Sans-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Mona-Sans-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/Mona-Sans-SemiBold.woff") format("woff"),
    url("../assets/fonts/Mona-Sans-SemiBold.ttf") format("truetype"),
    url("../assets/fonts/Mona-Sans-SemiBold.svg#Mona-Sans-SemiBold")
      format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mona-Sans";
  src: url("../assets/fonts/Mona-Sans-Regular.eot");
  src: url("../assets/fonts/Mona-Sans-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Mona-Sans-Regular.woff2") format("woff2"),
    url("../assets/fonts/Mona-Sans-Regular.woff") format("woff"),
    url("../assets/fonts/Mona-Sans-Regular.ttf") format("truetype"),
    url("../assets/fonts/Mona-Sans-Regular.svg#Mona-Sans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mona-Sans";
  src: url("../assets/fonts/Mona-Sans-Medium.eot");
  src: url("../assets/fonts/Mona-Sans-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Mona-Sans-Medium.woff2") format("woff2"),
    url("../assets/fonts/Mona-Sans-Medium.woff") format("woff"),
    url("../assets/fonts/Mona-Sans-Medium.ttf") format("truetype"),
    url("../assets/fonts/Mona-Sans-Medium.svg#Mona-Sans-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mona-Sans";
  src: url("../assets/fonts/Mona-Sans-Bold.eot");
  src: url("../assets/fonts/Mona-Sans-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Mona-Sans-Bold.woff2") format("woff2"),
    url("../assets/fonts/Mona-Sans-Bold.woff") format("woff"),
    url("../assets/fonts/Mona-Sans-Bold.ttf") format("truetype"),
    url("../assets/fonts/Mona-Sans-Bold.svg#Mona-Sans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mona-Sans";
  src: url("../assets/fonts/Mona-Sans-Light.eot");
  src: url("../assets/fonts/Mona-Sans-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Mona-Sans-Light.woff2") format("woff2"),
    url("../assets/fonts/Mona-Sans-Light.woff") format("woff"),
    url("../assets/fonts/Mona-Sans-Light.ttf") format("truetype"),
    url("../assets/fonts/Mona-Sans-Light.svg#Mona-Sans-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
  border-radius: 6;
}

::-webkit-scrollbar-thumb {
  border-radius: 6;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--scrollbar-thumb);
}

body {
  margin: 0;
  background-color: var(--body-bg) !important;
}
* {
  font-family: "Mona-Sans" !important;
  color: var(--primary-text-color);
}

h1 {
  font-size: 26px !important;
  font-weight: bold !important;
}
h2 {
  font-size: 24px !important;
  font-weight: 600 !important;
}
h3 {
  font-size: 18px !important;
  font-weight: 600 !important;
}
h4 {
  font-size: 18px !important;
  font-weight: 500 !important;
}
h5 {
  font-size: 16px !important;
  font-weight: 600 !important;
}
p {
  color: var(--textgrey) !important;
}
img {
  max-width: 100%;
}
.h-100 {
  height: 100%;
}
.p-24 {
  padding: 24px;
}
// Navbar Styles //
.navbar {
  position: sticky !important;
  z-index: 10 !important;
  background-color: var(--white) !important;
  .small-logo {
    display: none;
  }
  &.sidebar-closed {
    div {
      .navbar-left {
        gap: 0;
        min-width: 0;
        padding-left: 0;
        padding-right: 0;
        width: 0;
        .small-logo {
          display: block;
          width: 36.5px;
          position: relative;
          left: 10px;
        }
        .large-logo {
          display: none;
        }
        .toggle-btn {
          background-color: var(--color-green);
          position: relative;
          font-size: 24px;
          padding: 0;
          width: 30px;
          height: 30px;
          left: 45px;
          .open-menu {
            display: none;
          }
          .close-menu {
            display: block;
          }
        }
      }
      .navbar-right {
        width: 100%;
        padding-left: 45px;
      }
    }
  }
  .avatar-ui {
    border-radius: 100%;
    border: 1px solid var(--color-green);
  }
  div {
    padding: 0;

    .navbar-left {
      background-color: var(--primary-text-color);
      border-bottom: 1px solid var(--primary-text-color) !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 50px;
      min-width: 300px;
      padding: 14px 8px;
      box-sizing: border-box;
      transition: all 0.5s;
      -webkit-transition: all 0.25s;
      height: 64px;
      img {
        height: 37px;
        width: auto;
        position: relative;
        left: 10px;
        cursor: pointer;
      }
      svg {
        fill: var(--white);
      }
      .toggle-btn {
        .close-menu {
          display: none;
        }
      }
    }

    .navbar-right {
      margin: 0 32px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .user-info {
        display: flex;
        align-items: center;
        gap: 16px;
        font-size: 16px;
        font-weight: 500;
        button {
          text-transform: capitalize;
          color: var(--primary-text-color);
          font-size: 16px;
          font-weight: 500;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}
// Navbar Styles END //

// Sidebar Styles //
.sidebar-wrapper {
  // position: relative;
  z-index: 15 !important;
  .logo-box {
    // border: 2px solid yellow;
    // position: relative;
    gap: 0;
    padding: 10px;
    padding-left: 18px;
    padding-right: 0;
    .small-logo {
      display: block;
      width: 36.5px;

      position: relative;
      left: 10px;
    }
    .large-logo {
      width: auto;
      height: 37px;
    }
    .toggle-btn {
      position: relative;
      left: 100px;
      @media (max-width: 991.98px) {
        left: 26px;
      }
      .open-menu {
        font-size: 25px;
      }
      .close-menu {
        display: block;
      }
    }
  }
  &.sidebar-menu-closed {
    .MuiDrawer-paper {
      transform: none !important;
      visibility: unset !important;
      .list-wrap {
        width: 0;
        .menu-text-wrap {
          height: 24px;
          span {
            display: none;
          }
        }
      }
    }
  }
  &.sidebar-menu-open {
    .MuiDrawer-paper {
      background-color: #232323 !important;
      // height: 100vh !important;
    }
  }
  .MuiDrawer-paper {
    transition: all 0.5s !important;
    -webkit-transition: all 0.25s !important;
    overflow: hidden;
  }
  .list-wrap {
    // border: 3px solid red;
    height: 100vh;
    background-color: var(--primary-text-color);
    // padding-top: 72px !important;
    // padding-bottom: 16px;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; ////////////////////////
    overflow: auto;
    transition: all 0.5s;
    -webkit-transition: all 0.25s;
    .logo-box {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      // height: 64px;
      padding: 12px 18px;
    }
    ul.sidebar {
      display: flex;
      flex-direction: column;
      background-color: var(--primary-text-color);
      gap: 8px;
      padding-top: 15px;
      // border: 3px solid blue;
      li {
        padding: 0 4px;
        .icon-wrap {
          min-width: 35px !important;
          svg {
            fill: var(--textgrey);
            font-size: 25px;
          }
        }
        span {
          color: var(--textgrey);
          white-space: nowrap;
        }
        &:hover {
          svg {
            fill: var(--green);
          }
          span {
            color: var(--white);
          }
        }
      }
    }
    li.activemenu {
      .icon-wrap {
        svg {
          fill: var(--green) !important;
        }
      }
      span {
        color: var(--white) !important;
      }
    }
  }
}
.Menu-list-wrapper-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 65px);
  flex: 1;
  .first-menu-list {
    height: calc(100% - 71px);
    overflow: auto;
  }
  .last-menu-list {
  }
}
// Sidebar styles END //

// Content Layout //
.content-header {
  margin: 24px 32px 0px 32px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  h2 {
    margin: 0;
  }
}
.content-layout {
  border-radius: 12px;
  background-color: var(--white);
  margin: 24px 32px 32px 32px;
  h3 {
    margin-top: 0px;
  }
}

.chart-content {
  margin: 0;
  position: relative;
  top: 25px;
  left: 15px;
  padding-bottom: 16px;
}

.main-content {
  margin-left: 300px;
  transition: all 0.5s;
  -webkit-transition: all 0.25s;
  padding-bottom: 10px;
  &:has(.footer-wrapper) {
    padding-bottom: 75px !important;
  }
  &.remove-bottom-space {
    padding-bottom: 0;
  }
}
main.main-content.full-width {
  margin-left: 0;
}
.pattern-big,
.pattern-small {
  position: absolute;
  z-index: -1;
}
.pattern-big {
  top: 0;
  left: 0;
}
.pattern-small {
  right: 0;
  bottom: 0;
}

// Content Layout END //

// Common Styles //
.d-none {
  display: none !important;
}
.w-120 {
  min-width: 120px !important;
}
.btn {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  padding: 8px 16px !important;
}
.primary-btn {
  background-color: var(--primary-text-color) !important;
  color: var(--white) !important;
  &:hover {
    background-color: var(--primary-btn-hover) !important;
  }
}

.primary-btn {
  p {
    margin: 0;
    line-height: 21px;
    vertical-align: middle;
    color: var(--white) !important;
  }
}
.secondary-btn {
  background-color: var(--secondary-text-color) !important;
  color: var(--primary-text-color) !important;
  &:hover {
    background-color: var(--secondary-btn-hover) !important;
  }
}
.link-btn {
  text-transform: capitalize !important;
  font-size: 14px !important;
  color: var(--primary-text-color) !important;
  text-decoration: underline !important;
  margin: 0 !important;
  &:hover {
    background-color: transparent !important;
  }
}
.outline-btn {
  text-transform: capitalize !important;
  font-size: 14px !important;
  color: var(--primary-text-color) !important;
  background-color: transparent !important;
  border: 1px solid var(--primary-text-color) !important;
  &:hover {
    background-color: var(--secondary-text-color) !important;
  }
}
.green-btn {
  background-color: var(--green) !important;
  color: var(--white) !important;
  &:hover {
    background-color: var(--green-btn-hover) !important;
  }
}

.action-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-width: 40px !important;
  height: 40px;
  padding: 0 !important;
  border: none !important;
  border-radius: 10px !important;
  span {
    margin: 0;
  }
}
.add-btn {
  span {
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    font-size: 13px;
    svg {
      fill: var(--white);
    }
    &.filter-counte {
      border-radius: 7px;
      background-color: var(--white);
      color: var(--primary-text-color);
      font-size: 13px;
      line-height: 23px !important;
      height: 20px;
      margin-right: 8px;
      margin-left: 0;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.only-icon-btn {
  width: 42.5px !important;
  height: 42.5px;
  min-width: unset !important;
  span {
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    font-size: 13px;
    margin: 0;
    svg {
      fill: var(--white);
    }
  }
}
label {
  color: var(--primary-text-color) !important;
  font-size: 13px !important;
  margin-bottom: 4px !important;
  display: flex;
  font-weight: 700 !important;
}
label {
  .asterisk {
    color: var(--red) !important;
    font-size: 14px;
    line-height: 15px;
  }
}
.value {
  color: var(--primary-text-color) !important;
  margin: 0;
  font-size: 16px;
}

.description {
  color: var(--primary-text-color) !important;
  margin: 0;
  font-size: 16px;
  word-wrap: break-word;
}
.MuiModal-root {
  z-index: 9999;
}
.MuiRadio-root,
.MuiCheckbox-root {
  svg {
    fill: var(--textgrey);
  }
}
.MuiRadio-root.Mui-checked,
.MuiCheckbox-root.Mui-checked {
  svg {
    fill: var(--green);
  }
}
.MuiInputBase-input.MuiOutlinedInput-input,
.MuiInputBase-input.MuiOutlinedInput-input {
  font-size: 16px;
  height: 40px;
  box-sizing: border-box;
}
.title-with-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  button.back-btn {
    background-color: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
    min-width: fit-content !important;
    svg {
      font-size: 30px;
    }
  }
}
.MuiModal-root {
  .MuiModal-backdrop {
    backdrop-filter: blur(5px);
  }
}
.MuiPopover-root {
  .MuiModal-backdrop {
    backdrop-filter: inherit;
  }
}
.modal-wrapper {
  padding: 24px;
  max-width: 580px;
  width: 100%;
  width: calc(100% - 32px);
  border-radius: 12px;
  background-color: var(--white);
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .title {
    h3 {
      margin: 0;
      line-height: 27px;
    }

    p {
      color: var(--textgrey);
      font-size: 16px;
      margin: 8px 0 0 0;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
  .error {
    color: var(--red) !important;
    font-size: 14px !important;
  }
  .footer {
    margin-top: 36px;
    .btn-group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
      .btn {
        min-width: 100px;
      }
    }
  }
  .status-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fail-icon {
    align-self: center;
    color: var(--red);
    font-size: 52px;
  }
  .success-icon {
    align-self: center;
    color: var(--green);
    font-size: 52px;
  }
  .warning-icon {
    align-self: center;
    color: rgb(238, 255, 0);
    font-size: 52px;
  }
  .feedback-text {
    border-radius: 10px;
    padding: 20px;

    p {
      margin-bottom: 0;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-black);
    }
  }
  .fail-text {
    background: rgba(195, 90, 103, 0.2) !important;
  }
  .success-text {
    background: rgba(90, 195, 92, 0.2) !important;
  }
  .warning-text {
    background: rgba(183, 195, 90, 0.2) !important;
  }
  .confirmation-text {
    background: rgba(140, 199, 20, 0.2) !important;
    border: 1px solid var(--color-green);
    border-radius: 10px;
    padding: 20px;
  }
}
.action-btn.view-btn {
  background-color: var(--view-btn);
  span {
    svg {
      fill: var(--view-btn-icon);
    }
  }
  &:hover {
    background-color: var(--view-btn-hover);
  }
}
.action-btn.edit-btn {
  background-color: var(--edit-btn);
  span {
    svg {
      fill: var(--primary-text-color);
    }
  }
  &:hover {
    background-color: var(--edit-btn-hover);
  }
}
.action-btn.dlt-btn {
  background-color: var(--delete-btn);

  span {
    svg {
      fill: var(--delete-btn-icon);
    }
  }
  &:hover {
    background-color: var(--delete-btn-hover);
  }
}
.action-btn.export-btn {
  background-color: var(--export-btn);

  span {
    svg {
      fill: var(--export-btn-icon);
    }
  }
  &:hover {
    background-color: var(--export-btn-hover);
  }
}
.action-btn.convert-btn {
  background-color: var(--convert-btn);

  span {
    svg {
      fill: var(--convert-btn-icon);
    }
  }
  &:hover {
    background-color: var(--convert-btn-hover);
  }
}
.action-btn.upload-btn {
  background-color: transparent;
  border: 1px solid var(--primary-text-color) !important;
  height: 40px;
  width: 120px;
  color: var(--primary-text-color) !important;
  line-height: 40px;
  span {
    color: var(--primary-text-color) !important;
    svg {
      fill: var(--export-btn-icon);
    }
  }
  &:hover {
    background-color: var(--primary-text-color);
    color: var(--white) !important;
    span {
      color: var(--primary-text-color) !important;
    }
  }
}

.user-listing {
  .tab-area {
    button {
      span.user-name {
        text-transform: capitalize;
        font-weight: 600;
        font-size: 16px;
      }
    }
    button.Mui-selected {
      span.role-name {
        color: var(--primary-text-color);
      }
    }
    .MuiTabs-indicator {
      background-color: var(--green);
    }
    .MuiTabs-flexContainer {
      overflow: auto;
    }
  }
}
.content-header-right .search-bar .cross-line {
  cursor: pointer;
  font-size: 18px;
  left: 90%;
  position: absolute;
  top: 10px;
  z-index: 2;
}
.content-header-right {
  // border: 2px solid red;
  display: flex;
  gap: 24px;
  align-items: center;
  flex-wrap: wrap;
  // .first-box{
  //   display: flex;
  //   gap: 24px;
  // }
  .search-bar {
    position: relative;
    svg {
      position: absolute;
      top: 10px;
      left: 8px;
      font-size: 18px;
      z-index: 2;
    }
    input {
      width: 260px;
      background-color: var(--white);
      padding-left: 32px !important;
      box-shadow: 0px 4px 4px 0px var(--secondary-text-color);
      border-radius: 10px !important;
      border-color: var(--textgrey) !important;
    }
    fieldset {
      border-radius: 10px !important;
      border: none !important;
    }
  }
}

.input-field {
  .MuiOutlinedInput-root {
    border-radius: 8px;
    border-color: var(--border) !important;
    &:focus-visible {
      outline: none !important;
    }
    &.Mui-focused {
      fieldset {
        border-width: 1px !important;
        border-color: var(--primary-btn-hover) !important;
      }
    }
    &.Mui-disabled {
      color: var(--primary-text-color);
      background-color: var(--secondary-text-color);
      input {
        -webkit-text-fill-color: var(--primary-text-color);
      }
    }
  }
}
.muilt-select-field {
  .select__placeholder {
    color: rgba(35, 35, 35, 0.3);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .select__menu {
    z-index: 9;
  }
}
.textarea-field {
  overflow: auto !important;
  // width: calc(100% - 34px);
  width: 100%;
  padding: 16px;
  min-height: 118px;
  max-height: 118px;
  border-radius: 8px;
  border-color: var(--border);
  border-width: 1px !important;
  box-shadow: none !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;

  &::-webkit-input-placeholder {
    color: rgba(35, 35, 35, 0.3);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(35, 35, 35, 0.3);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(35, 35, 35, 0.3);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &:-ms-input-placeholder {
    // color: rgba(35, 35, 35, 0.30);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &.modal-textarea {
    min-height: 100px;
  }
  &.has-error {
    border-color: red;
  }
}
.footer-wrapper {
  // border-top: 1px solid var(--border);
  // background: var(--white);
  // padding-top: 16px;
  // padding-bottom: 16px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // justify-content: center;
  // gap: 16px;
  // padding-right: 32px;
  // position: fixed;
  // bottom: 0;
  // width: 100%;

  border-top: 1px solid var(--border);
  background: var(--white);
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  // padding-right: 32px;
  position: fixed;
  bottom: 0;
  // max-width: calc(100% - 260px);
  width: 100%;
  z-index: 0;
  left: 0;
  .secondary-btn {
    margin-right: 32px;
  }
}
// Common Styles END //

// Table //
Table.listing-table {
  thead {
    tr {
      th {
        font-weight: 700;
        white-space: nowrap;
      }
      th.th-role-name {
        width: 80%;
      }
      th.th-action {
        width: 20%;
      }
    }
  }
  .action-btn-wrap {
    display: flex;
    gap: 16px;
  }
  tbody {
    position: relative;
    .table-no-data {
      width: 100%;
      display: block;
      text-align: center;
      padding: 40px 0;
      position: absolute;
      background-color: var(--white);
      height: 450px;
      margin-top: -6px;
    }
    tr {
      td {
        color: var(--primary-text-color);
        white-space: nowrap;
        .project-desc {
          color: rgba(35, 35, 35, 0.8);
          width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
        }
      }
    }
  }
}
.app-name {
  display: flex;
  align-items: center;
  gap: 16px;
}
.text-center {
  text-align: center;
}
.more-desc {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-no-data-tbody {
  position: relative;
  background-color: var(--white);
  height: 500px;
}
.table-no-data {
  width: 100%;
  display: block;
  text-align: center;
  margin: 40px 0;
  position: absolute;
  img {
    width: 160px;
  }
  h5 {
    margin: 0 0 16px 0;
  }
  P {
    margin: 4px;
  }
  button {
    margin-top: 24px;
  }
}
// Table END//

.app-logo {
  margin: 40px 0;
  .logo-image-wrap {
    display: inline-flex;
    position: relative;
    .logo-image {
      background-color: var(--secondary-text-color);
      border-radius: 10px;
      height: 120px;
      width: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    button.upld-btn {
      position: absolute;
      right: -10px;
      bottom: -5px;
      height: 32px !important;
      background-color: var(--primary-text-color);
      border-radius: 50%;
      min-width: 32px !important;
      svg {
        fill: var(--white);
        font-size: 16px;
      }
    }
  }
}

.footer-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 64px;
  .btn-group {
    gap: 16px;
    display: flex;
  }
}

.app-list {
  display: flex;
  align-items: center;
  gap: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  p {
    margin: 0;
    color: var(--primary-text-color) !important;
    font-weight: 500;
    white-space: nowrap;
    span {
      font-weight: 500;
    }
  }
}

.tag-wrap {
  display: flex;
  gap: 8px;
  .MuiChip-root {
    background-color: var(--light-green);
    text-transform: capitalize;
    color: var(--primary-text-color);
    border-radius: 4px;
    padding: 8px 16px;
    white-space: nowrap;
    font-weight: 500;
    height: 30px;
    span {
      padding: 0;
      height: 18px;
    }
  }
}
.app-list {
  p {
    color: var(--primary-text-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
  }
}

// Login screen //
.login-main-wrap {
  background-color: var(--primary-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  gap: 40px;
  .logo-wrap {
    text-align: center;
    img {
      width: 250px;
    }
    h2 {
      margin: 12px 0 0 0;
      color: var(--white);
    }
  }
  .login-form-wrap {
    background-color: var(--white) !important;
    border-radius: 12px;
    padding: 32px;
    position: relative;
    z-index: 2;
    .title {
      text-align: center;
      margin-bottom: 24px;
      h3 {
        margin: 0;
      }
      p {
        margin: 12px 0 0 0 !important;
      }
    }
    button.primary-btn {
      width: 100%;
      margin-top: 24px;
    }
    .forgot-pw {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 16px;
      .link-btn {
        margin-top: 8px !important;
        padding: 0 !important;
      }
    }
  }
  .pattern-big,
  .pattern-small {
    z-index: 1;
  }
  .pattern-big {
    top: -50px;
    left: -170px;
  }
}
// Login screen END //

.page-not-found {
  height: 100vh;
  .page-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    img {
      max-width: 100%;
    }
    .page-404 {
      max-width: 35%;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 36px !important;
      margin-bottom: 8px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
    p {
      font-size: 20px;
      margin-bottom: 24px;
      color: var(--primary-btn-hover) !important;
    }
  }
}
.error-message,
.error-message-login,
.Mui-error.MuiFormHelperText-sizeSmall {
  color: red !important;
  margin-left: 0 !important;
  margin-top: 4px !important;
}
.MuiFormControl-root.MuiTextField-root {
  width: 100%;
}
.input-field .MuiTextField-root.Mui-error .MuiOutlinedInput-root {
  border-color: red;
}
.error .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: red;
}

// activity card with icon
.activity-card-wrapper {
  .activity-card {
    cursor: pointer;
    background: var(--color-black);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h6 {
      color: var(--white);
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;

      @media (max-width: 1280.98px) {
        font-size: 18px;
        line-height: 28px;
      }
    }
    p {
      color: var(--white) !important;
      margin: revert !important;
    }

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 45px;
      margin-top: 10px;
      display: block;
      line-height: 50px;

      @media (max-width: 1280.98px) {
        font-size: 35px;
        line-height: 40px;
      }
    }

    svg {
      color: var(--color-green) !important;
      width: 70px;
      height: 70px;
      stroke-width: 1.5;

      polyline {
        color: var(--color-green) !important;
      }

      @media (max-width: 1280.98px) {
        width: 50px;
        height: 50px;
      }
    }
  }
}

.rs-picker-daterange-menu {
  z-index: 10000000000; /* added a large random number, you can choose it more carefully based on your use-case.*/
}
.app-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.release-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background: var(--color-black);
  border: 2px solid var(--color-green);
  cursor: pointer;

  @media (max-width: 1560.98px) {
    width: 36px;
    height: 36px;
  }
  .release-icon {
    fill: var(--color-green);
  }
}
.version-name {
  font-size: 22px;
  color: var(--color-black) !important;
  font-weight: 500;
  line-height: normal;
}

.release-note {
  // padding: 10px;

  .heading {
    display: flex;
    flex-direction: row;
    gap: 28px;
    justify-content: flex-start;
    align-items: center;

    .release-action-container {
      margin-left: auto;
      align-items: center;
    }

    .version-name {
      font-size: 22px;
      color: var(--color-black);
      font-weight: 500;
      line-height: normal;
    }

    .date {
      font-size: 18px;
      color: var(--color-grey);
      font-weight: 500;
      line-height: normal;
      font-style: italic;
    }
    .down-arrow {
      // margin-left: auto;
      margin-right: 42px;
      font-size: 32px;
      color: var(--color-grey);
      cursor: pointer;
    }
    .rotate-transition {
      transition: transform 0.3s ease; /* Adjust the duration as needed */
    }
  }

  .content {
    background-color: #f9f9f9 !important;
    margin-top: 15px;
  }
}
.release-page {
  background: transparent !important;
  .card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    height: auto;
    padding: 15px;
    margin-bottom: 20px;
  }
}
.load-more-section {
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    width: max-content !important;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #8cc714;
    border: 1px solid var(--color-green);
    color: #fff;
    font-size: 16px;
    svg {
      fill: #ffffff;
    }
  }
}

.filter-apply-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  padding: 0px 0px 0 0px;
  .outline-btn {
    &.add-btn {
      span {
        margin-left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        font-size: 13px;
        svg {
          fill: var(--primary-text-color);
        }
      }
    }
  }
}

.w-full {
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.rs-picker-date-menu {
  z-index: 9999 !important;
  .rs-btn.rs-btn-primary {
    background-color: var(--primary-text-color);
    color: #fff;
  }
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
    background-color: var(--green);
    color: #fff !important;
  }
  .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
    background-color: var(--primary-text-color);
  }
  .rs-calendar-time-view .rs-calendar-header-title-time {
    color: var(--primary-text-color);
  }
  .rs-calendar-time-dropdown-cell:hover,
  .rs-calendar-table-cell-content:hover {
    background-color: var(--secondary-text-color);
    color: var(--priamry-text-color);
  }
}
.modal-date-picker {
  width: 100%;
  border-color: var(--border);
  border-radius: 10px;
  .rs-picker-toggle.rs-btn {
    border-radius: 10px;
    .rs-picker-toggle-placeholder,
    .rs-picker-toggle-textbox {
      color: rgba(35, 35, 35, 0.3);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .rs-picker-toggle-value {
      color: var(--primary-text-color);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &:not(.rs-picker-disabled) .rs-picker-toggle-active,
  &:hover .rs-picker-toggle {
    border-color: var(--primary-text-color) !important;
    box-shadow: unset !important;
  }
}

.modal-date-picker .rs-picker-toggle.rs-btn {
  z-index: 0;
}

.rs-stack-item {
  display: flex !important;
}

.rs-picker-daterange-header {
  display: none !important;
}

.rs-picker-daterange-calendar-group .rs-calendar:last-child {
  display: none;
}

.rs-picker-daterange-calendar-group {
  min-width: auto;
}

.rs-picker-menu {
  z-index: 9999 !important;
}
.rs-picker-menu .rs-picker-toolbar {
  flex-direction: column;
}

.rs-picker-menu .rs-picker-toolbar-ranges {
  flex-direction: column;
}

.rs-picker-toggle {
  border: 1px solid var(--color-grey);
  border-radius: 10px !important;
}
.rs-picker-toggle:hover {
  border-color: var(--color-green) !important;
}
.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  box-shadow: none;
  border-color: var(--color-green) !important;

  &:hover {
    border-color: var(--color-green) !important;
  }
}

.rs-btn-icon {
  background: var(--color-green);
  border: 1px solid var(--color-green);
  border-radius: 5px;
  padding: 0;
}

.rs-btn-icon svg {
  color: var(--color-white);
  font-size: 20px !important;

  &:hover {
    fill: var(--color-green) !important;
  }
}

.rs-calendar-header-title {
  margin: 0 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
}

.rs-calendar-table-header-cell {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.rs-calendar-table-cell-content span {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.rs-calendar-table-cell-selected-start .rs-calendar-table-cell-content,
.rs-calendar-table-cell-selected-end .rs-calendar-table-cell-content {
  background: var(--color-green);
  color: var(--color-white);
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px var(--color-green);
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background: var(--color-green) !important;
}

.rs-calendar-table-cell-content:hover {
  background-color: var(--color-green);
  color: var(--color-white);
}

.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell-selected:hover
  .rs-calendar-table-cell-content {
  background-color: var(--color-green);
  color: var(--color-white);
}

.rs-calendar-table-cell:hover {
  background-color: transparent !important;
}

.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  background-color: var(--color-green);
  color: var(--color-white);
}

.rs-calendar-table-cell-in-range::before {
  background: rgba(140, 199, 20, 0.3);
  border-top: 1px solid var(--color-green);
  border-bottom: 1px solid var(--color-green);
}

.rs-stack-item {
  width: 100%;
  text-align: center;
}

.rs-stack-item .rs-btn-link {
  width: 171px;
  text-align: center;
  border: 1px solid var(--color-green);
  border-radius: 5px;
  color: var(--color-black) !important;
  text-decoration: none !important;
}

.rs-stack-item .rs-btn-link:hover {
  background: rgba(140, 199, 20, 0.3);
}

.rs-picker-toolbar-right .rs-btn.rs-btn-primary.rs-btn-sm {
  width: 100%;
  background-color: var(--color-green) !important;
  color: var(--color-white) !important;
  top: 5px;
}

.rs-picker-toolbar-right {
  width: 100%;
}

.rs-calendar-month-view .rs-calendar-header-title-date {
  color: var(--color-green);
}

.rs-calendar-month-dropdown-year-active {
  color: var(--color-green);
}

.rs-calendar-month-dropdown-cell-active
  .rs-calendar-month-dropdown-cell-content,
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
  background-color: var(--color-green) !important;
  color: var(--color-white) !important;
}

.rs-picker-menu .rs-picker-toolbar {
  flex-direction: column;
}

.rs-picker-toolbar .rs-stack-item {
  max-width: 171px;
}

.rs-picker-toggle-value {
  display: flex;
}

.MuiDayCalendar-weekContainer {
  .Mui-selected {
    background-color: #7cb900 !important;
  }
  .MuiPickersDay-today {
    border: 1px solid #7cb900 !important;
  }
}

.w-120 {
  min-width: 120px !important;
}

.apexcharts-tooltip ul {
  list-style: none;
  padding: 0;
  color: #fff;
  margin-bottom: 0;
}
.apexcharts-tooltip ul li {
  color: #232323;
  font-weight: 700;
  margin-bottom: 4px;
}

.apexcharts-tooltip .tooltipTitle {
  color: #232323;
  padding: 6px 10px;
  font-weight: 500;
  font-size: 14px;
  background-color: #e9e8e8;
}

.apexcharts-tooltip-text-goals-value,
.apexcharts-tooltip-text-y-value,
.apexcharts-tooltip-text-z-value {
  margin-left: 0px !important;
}

.apexcharts-tooltip > div {
  padding: 0;
}

.apexcharts-tooltip-text-y-value {
  padding: 10px 10px 8px;
  margin-left: 0;
}

.apexcharts-tooltip ul li span {
  text-transform: capitalize;
  color: #232323;
  font-weight: normal;
  display: inline-block;
}

.apexcharts-tooltip-series-group {
  background-color: #ffffff !important;
}

.apexcharts-tooltip ul li:last-child {
  margin-bottom: 0;
}

.apexcharts-tooltip-y-group {
  padding: 0 !important;
}

.apexcharts-tooltip-title .title {
  color: #232323;
  padding: 6px 10px;
  font-weight: 500;
  font-size: 14px;
  background-color: #e9e8e8;
  display: inline-block;
}
.custom-tooltip span.apexcharts-tooltip-text-y-value {
  padding: 0;
}
.custom-tooltip .apexcharts-tooltip-series-group {
  padding: 10px !important;
}
.custom-tooltip
  .apexcharts-tooltip.apexcharts-theme-light
  .apexcharts-tooltip-title {
  margin-bottom: 0;
}
.custom-tooltip .apexcharts-tooltip-marker {
  margin-right: 6px;
}

.modal-ui {
  padding: 0;
  .modal-body {
    padding: 24px;
  }
}
.purchage-request-module-wrapper {
  flex-wrap: wrap-reverse;
  gap: 24px;
}
.purchase-request-view-wrapper {
  flex-wrap: wrap-reverse;
  gap: 24px;
}
.vendor-module-wrapper {
  flex-wrap: wrap-reverse;
  gap: 24px;
}
.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  flex-wrap: wrap;
  gap: 16px;
  padding: 0 24px 24px 24px;
  .rows-per-page-wrapper {
    gap: 12px;
    display: flex;
    align-items: center;
    p {
      margin-top: 4px;
      white-space: nowrap;
    }
    .MuiFormControl-root {
      margin-top: 0;
      margin-bottom: 0;
      border-color: #777 !important;
      box-shadow: none !important;
      .MuiInputBase-input {
        padding-left: 10px;
        padding-right: 20px;
        padding-top: 22.5px !important;
        padding-bottom: 22.5px;
      }
      svg {
        right: 0;
        top: 9px !important;
        padding-top: 0 !important;
      }
      fieldset {
        border-color: #777 !important;
      }
      &.Mui-Focused {
        fieldset {
          border-color: transparent !important;
        }
      }
    }
  }
  .MuiPaginationItem-root {
    border-radius: 8px !important;
  }
}
.report-grid-list {
  .report-card {
    align-items: center;
    background-color: var(--body-bg);
    border: 2px solid var(--textgrey);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    gap: 12px;
    padding: 12px;
    height: 100%;
    justify-content: space-between;
  }
  .report-card-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .setting-icon {
    svg {
      max-width: 65px;
      min-width: 65px;
      height: auto;
    }
  }
}
.report-asset-valuation-wrapper {
  flex-wrap: wrap-reverse;
  gap: 24px;
}
.p-0 {
  padding: 0 !important;
}

@media (max-width: 1048.98px) {
  .report-asset-valuation-wrapper {
    .filter-apply-wrapper {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .content-header-right {
      width: 100%;
      .primary-btn {
        flex: 1;
      }
    }
  }
}
@media (max-width: 991.98px) {
  .main-content {
    margin-left: 0 !important;
  }
  .content-header {
    margin: 32px 16px 0px 16px;
  }
  .content-wrapper,
  .content-layout {
    margin: 24px 16px 32px 16px !important;
  }
  .navbar div .navbar-left {
    position: fixed;
  }
  .navbar div .navbar-right {
    margin-right: 16px;
  }
  .back-btn {
    display: none !important;
  }
  .footer-wrapper {
    .secondary-btn {
      margin-right: 16px;
    }
  }
  .navbar.sidebar-closed div .navbar-left .toggle-btn {
    left: 26px;
  }
  .navbar.sidebar-closed div .navbar-right {
    padding-left: 30px;
  }
  .sidebar-wrapper {
    .logo-box {
      // border: 2px solid red;
      .toggle-btn {
        left: 95px;
      }
    }
    z-index: 9;
  }
}
@media (max-width: 804.98px) {
  .purchage-request-module-wrapper {
    .content-header-right {
      width: 100%;
      .search-bar {
        // border: 2px solid red;
        width: 100%;
        flex: 1;
        input {
          width: 100%;
        }
      }
      
    }
  }
}
@media (max-width: 768.98px) {
   
  .navbar.sidebar-closed {
    .navbar-right {
      h1 {
        font-size: 20px !important;
      }
    }
  }
  .avatar-ui {
    width: 30px !important;
    height: 30px !important;
  }
  .release-button {
    width: 36px;
    height: 36px;
  }
  // .app-button {
  //   svg {
  //     margin-right: 8px !important;
  //   }
  // }
}
@media (max-width: 700.98px) {
  .purchage-request-module-wrapper {
    .content-header-right {
      display: grid;
      grid-row: 1fr 1fr;
      width: 100%;
      .search-bar {
        // border: 2px solid green;
        width: 100%;
        flex: 1;
        input {
          width: 100%;
        }
      }
      .select-company{
        width: 100%;
      }
      
    }
  }
  .purchase-request-view-wrapper {
    .content-header-right {
      width: 100%;
      .primary-btn {
        width: 100%;
        flex: 1;
      }
    }
  }
}
@media (max-width: 600.98px) {
  .report-asset-valuation-wrapper {
    .content-header-right {
      .primary-btn {
        flex: 1 auto;
      }
    }
  }
}
@media (max-width: 592.98px) {
  .vendor-module-wrapper {
    .content-header-right {
      
      width: 100%;
      .search-bar {
        // width: 100%;
        flex: 1;
        input {
          width: 100%;
        }
      }
      .select-company{
        width: 100%;
      }
      .add-purchase{
        width: 100%;
      }
    }
  }
}
@media (max-width: 576.98px) {
  .purchage-request-module-wrapper {
    .content-header-right {
      // border: 3px solid forestgreen;
      // flex-direction: column;
      // .first-box{
      //   flex-direction: column;
      // }
      .search-bar {
        flex: 1 auto;
      }
      .primary-btn {
        flex: 1;
      }
    }
  }
}
@media (max-width: 480.98px) {
  .vendor-module-wrapper {
    .content-header-right {
      
      .search-bar {
        flex: 1 auto;
      }
      .primary-btn {
        width: 100%;
      }
    }
  }
  .drawer-footer-btn {
    flex-wrap: wrap;
    .btn {
      flex: 1;
    }
    .green-btn {
      flex: 1 auto;
      width: 100% !important;
    }
  }
  .user-module-wrapper {
    gap: 24px !important;
    flex-direction: column-reverse;
    align-items: flex-start;
    .content-header-right {
      width: 100%;
      .search-bar {
        width: 100%;
        input {
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 400.98px) {
  .report-asset-valuation-wrapper {
    .content-header-right {
      .primary-btn {
        width: 100%;
      }
    }
  }
}

.drawer-date-picker-container {
  .rs-picker-daterange-panel {
    .rs-stack-item {
      .rs-btn-link {
        width: 120px !important;
      }
    }
  }
  .rs-picker-toolbar .rs-stack-item {
    max-width: 120px;
  }
  .rs-picker-toolbar-right {
    width: fit-content;
    .rs-btn.rs-btn-primary.rs-btn-sm {
      width: 120px;
    }
  }
}

@media (max-width: 440.98px) {
  .drawer-date-picker-container {
    .rs-picker-daterange-panel {
      .rs-stack-item {
        .rs-btn-link {
          width: 100px !important;
        }
      }
    }
    .rs-picker-toolbar .rs-stack-item {
      max-width: 100px;
    }
    .rs-picker-toolbar-right {
      width: fit-content;
      .rs-btn.rs-btn-primary.rs-btn-sm {
        width: 100px;
      }
    }
  }
}
@media (max-width: 390.98px) {
  .drawer-date-picker-container {
    .rs-calendar {
      width: 100%;
    }
    .rs-calendar:first-child {
      border: none !important;
    }
    .rs-picker-toolbar-ranges {
      justify-content: flex-start;
      width: 100% !important;
      flex: 1 !important;
      flex-direction: row-reverse;
      .rs-stack-item {
        width: fit-content !important;
      }
    }
    .rs-picker-daterange-panel {
      .rs-stack-item {
        flex-direction: column-reverse;
      }
      .rs-stack-item {
        flex: 1;
        .rs-btn-link {
          width: 100% !important;
          flex: 1;
        }
      }
    }
    .rs-picker-toolbar .rs-stack-item {
      max-width: 100%;
      width: 100% !important;
      flex: 1;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .rs-picker-toolbar-right {
      width: 100%;
      .rs-btn.rs-btn-primary.rs-btn-sm {
        width: 100%;
      }
    }
  }
}

// --------------- header view acc ---------------------
.account-dropdown-menu {
  .MuiMenu-paper {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    padding: 10px 16px;
    border-radius: 10px;
    .user-details {
      .user-img {
        display: flex;
        justify-content: center;
        align-items: center;
        // width: 60px;
        // height: 40px;
        // border: 2px solid var(--green);
        // width: 100% !important;
        // height: 80px;
        // background-color: var(--grey);
        // padding: 11px;
        // border-radius: 100px;
        // border: 2px solid red;
        // margin: 0 auto;

        @media (max-width: 1560.98px) {
          width: 100%;
          height: 100%;
        }
        img {
          object-fit: contain;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: rgb(204, 204, 204);
          // padding: 8px;
        }
        // .avtaarImg {
        //   width: 100%;
        //   height: 100%;
        // }
      }
      .user-name {
        //   border: 2px solid var(--green);
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 24px;
        text-align: center;
      }
      .user-position {
        text-align: center;
        display: block;
        font-weight: 400 !important;
        font-size: 13px;
        line-height: 18px;
      }
    }
    .divider-border {
      background: var(--light-green);
      margin: 15px 0px;
    }
    .organization-details {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 10px;
      margin-bottom: 10px;
      gap: 10px;
      .org-img {
        width: 30px;
        height: 30px;
        background-color: var(--black);
        padding: 2px;
        border-radius: 100px;
        border: 2px solid var(--green);
        margin-bottom: 5px;
        img {
          width: 100%;
          border-radius: 100%;
        }
      }
      .org-text {
        h5 {
          font-weight: 500 !important;
          font-size: 15px !important;
          //   line-height: 15px;
          white-space: nowrap;
        }
        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          position: relative;
          top: -6px;
        }
      }
    }
    .links {
      gap: 15px;
      display: flex;
      flex-direction: column;
      .menu-link {
        // gap: 14px;
        padding: 0;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        min-height: auto;
        &:hover,
        &:focus {
          background-color: transparent;
        }
        .link-icon {
          margin-left: 6px;
          min-width: 22px;
          opacity: 0.6 !important;
          font-size: 18px !important;
        }
        .link-text {
          margin-left: 10px;
        }
      }
    }
  }
}
.no-scroll {
  overflow: hidden;
}
