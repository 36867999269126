.content-header {
  &.right-wrapper {
    @media (max-width: 1190.98px) {
      margin-top: 0;
    }
  }
}
.content-wrapper {
  &.left-wrapper {
    @media (max-width: 1190.98px) {
      margin-bottom: 0 !important;
    }
  }
}

.report-card-wrapper{
  align-items: center;
  background-color: var(--body-bg);
  border: 2px solid var(--textgrey);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 160px;
  justify-content: center;
  .app-logo {
      margin: 0;
      img{
          height: 70px;
          width: auto;
      }
  }
  .setting-icon {
      margin: 0;
      width: 65px;

      img {
          width: 100%;
      }
  }

  .card-name {
      margin: 0;
      font-size: 20px !important;
      color: var(--primary-text-color);
  }
}
.activity-padding {
  margin-top: 95px;
  margin-left: 0px;
}

.setting-icon {
  width: 65px;

  img {
    width: 100%;
  }
}
.setting-asset-category-module-wrapper{
  flex-wrap: wrap-reverse;
  gap: 24px !important;
}
@media(max-width:810.98px){
  .setting-asset-category-module-wrapper{
    .content-header-right{
      width: 100%;
      .search-bar{
        width: 100%;
        flex: 1;
        input{
          width: 100%;
        }
      }
    }
  }
}
@media(max-width:576.98px){
  .setting-asset-category-module-wrapper{
    .content-header-right{
      .search-bar{
        width: 100%;
        flex: 1 auto;
      }
      .primary-btn{
        width: 100%;
      }
    }
  }
}